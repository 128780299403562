import React, { useEffect, useState } from 'react';
import Header from '../component/Header';
import createnew from '../images/createnew.png';
import LOGO from '../images/logo.svg';
import { Lock, Edit2, Trash2 } from 'react-feather';
// import { Modal } from 'bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
// import listinglogo from '../images/listinglogo.png';

function Listing() {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteGptName, setDeleteGptName] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetch_Data();
    }, []);

    const fetch_Data = () => {
        setLoading(true)
        fetch("https://api.srvr2px.cyberads.io/cm_chatgpt/accounts_list")
            .then(response => response.json())
            .then(result => {
                setData(result);
            })
            .catch(() => {
                toast.error("something went wrong");
            })
            .finally(()=>{
                setLoading(false)
            });
    };

    const handle_Delete = () => {
        if (deleteGptName) {
            fetch(`https://api.srvr2px.cyberads.io/cm_chatgpt/delete_account/${deleteGptName}`, {
                method: "PUT",
                headers: {
                    "Accept": "application/json",
                }
            })
                .then((response) => {
                    if (response.ok) {
                        fetch_Data();
                    } else {
                        toast.error("failed to delete data");
                    }
                })
                .catch(() => {
                    toast.error("something went wrong while deleting data");
                })
                .finally(() => {
                    setShowModal(false);
                });
        }
    };

    const handlePreview = (gpt_name, logoPath, user_type) => {
        navigate(`/preview/${gpt_name}`, { state: { logoPath, user_type } });
    };

    const filteredData = data.filter(item =>
        item.gpt_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <Header header_name="My GPTs" />
            <div className='p-3 p-lg-5'>
                <div className='d-flex flex-column align-items-center'>
                    <div className='d-flex flex-row create-gpt'>
                        <Link to={'/configue'}>
                            <img className='create-new-icon' src={createnew} alt="" />
                        </Link>
                        <div className='d-flex flex-column ms-2'>
                            <h5>Create a GPT</h5>
                            <p className='text-color-a font-14 mb-2'>Customize a version of ChatGPT for a specific purpose.</p>
                        </div>
                    </div>
                    <hr className='m-0 w-75' />
                </div>
                <div className='d-flex justify-content-center'>
                    <input
                        type="text"
                        className="form-control w-50 mt-3 mb-3"
                        placeholder="Search GPTs..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className='d-flex justify-content-center'>
                   
                    {loading ? (
                        <div>Loading...</div> // Replace this with your loader component if you have one
                    ) : (
                        <table className=''>
                            {filteredData.length > 0 ? (
                                filteredData.map(item => (
                                    <tr className='row-style' key={item.gpt_name}>
                                        <td
                                            className='px-2 py-4 px-md-2 px-lg-5 font-500'
                                            onClick={() => handlePreview(item.gpt_name, item.logo_path, item.user_type)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <img src={item.logo_path || LOGO} className="listing-logo me-2 rounded-circle" alt="logo" />
                                            {item.gpt_name}
                                        </td>
                                        <td className='px-2 py-4 px-md-4 px-lg-5 text-color-a font-14 font-500'>{item.description}</td>
                                        <td className='px-2 py-4 px-md-2 px-lg-5'>
                                            <Link to={`/edit/${item.gpt_name}`}>
                                                <button className='border-0 bg-transparent'>
                                                    <Edit2 className='me-1' size={18} color='#777' />
                                                </button>
                                            </Link>
                                        </td>
                                        <td className='px-2 py-4 px-md-2 px-lg-5'>
                                            <button
                                                className='border-0 bg-transparent'
                                                onClick={() => {
                                                    setShowModal(true);
                                                    setDeleteGptName(item.gpt_name);
                                                }}
                                            >
                                                <Trash2 className='me-1' size={18} color='#777' />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className='text-center py-4'>
                                        No GPT found.
                                    </td>
                                </tr>
                            )}
                        </table>
                    )}
                    {showModal && (
                        <div className="modal show" style={{ display: "block" }} role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title fs-5">Delete GPT?</h4>
                                        <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <p className='text-color-a font-14 mb-0'>Are you sure you want to delete this GPT?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                                        <button type="button" className="btn btn-danger" onClick={handle_Delete}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Listing;
