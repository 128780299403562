import React, { useRef, useState } from 'react';
import '../style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Modal } from 'bootstrap';
import defaultprofile from '../images/defaultprofile.png'
import * as yup from "yup";
import axios from 'axios';
import { Trash2, File } from 'react-feather';
import Header from '../component/Header';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import info from "../images/info.svg";
import addnew from "../images/addnew.png";

function Configue() {
    const [formdata, setformData] = useState({
        "email": "demo@gmail.com",
        "user_type": "",
        "account_type": "",
        "gpt_name": "",
        "description": "",
        "prompt": "",
        "conversational_starter": "",
        "file_name": [],
        "unique_file_name": [],
        "logo": null
    });
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const [progress, setProgress] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const[uploading,setUpoading] = useState(false);



    const validateSchema = yup.object({
        // logo: yup.mixed().required("Logo is required"),`
        gpt_name: yup.string()
            .required("Name is required")
            .max(50, "Name must be at most 50 characters"),
        // .test(
        //     'is-valid-name',
        //     'Special characters are not allowed',
        //     value => /^[a-zA-Z0-9\s]*$/.test(value)
        // )
        // .test(
        //     'no-whitespace',
        //     'Whitespace is not allowed',
        //     value => /^\S*$/.test(value)
        // ),
        user_type: yup.string().required("Select User Type"),
        account_type: yup.string().required("Select Business Type"),
        description: yup.string().required("Description is required"),
        prompt: yup.string().required("Instructions is required"),
        conversational_starter: yup.string().required("Conversation Starter is required"),
        // file_name: yup.string().required("Atleast one file is required")


    });

    const handleBlur = async (field) => {
        try {
            // Directly pass formdata to validateAt
            await validateSchema.validateAt(field, formdata);
            setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    
            if (field === "gpt_name") {
                try {
                    // Check if GPT name already exists
                    const response = await axios.get(`https://api.srvr2px.cyberads.io/cm_chatgpt/name/${formdata.gpt_name}`);
                    if (response.data.exists) {
                        setUpoading(false);
                        setErrors((prevErrors) => ({ ...prevErrors, gpt_name: 'GPT name already exists' }));
                    } else {
                        setUpoading(true);
                        setErrors((prevErrors) => ({ ...prevErrors, gpt_name: "" }));
                    }
                } catch (error) {
                    console.error("Error checking GPT name:", error);
                    // setErrors((prevErrors) => ({ ...prevErrors, gpt_name: "Error checking GPT name" }));
                }
            }
        } catch (error) {
            setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
        }
    };
    // for uploading file
    const handleFileChange = (e) => {
        if (!formdata.gpt_name) {
            toast.error("Please fill out the required fields above before uploading a file.");
            // Reset the file input value after deletion
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
            return;
        }
        const files = e.target.files;
        if (files.length > 6) {
            // If more than six files are selected, display an error message
            setErrors({ ...errors, "file_limit": "You can upload a maximum of six files" });
            return;
        }
        // Process each file immediately after it is selected
        Array.from(files).forEach(file => {
            setProgress(prevProgress => ({ ...prevProgress, [file.name]: 0 })); // Initialize progress
            uploadFile(file); // Name is accessed directly within uploadFile
        });
    };

    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append("gpt_name", formdata.gpt_name); // Directly use the name from the state
        formData.append("files", file);

        axios.post('https://api.srvr2px.cyberads.io/cm_chatgpt/upload_files', formData, {
            headers: {
                "Accept": "application/json",
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(prevProgress => ({
                    ...prevProgress,
                    [file.name]: percentCompleted
                }));
            }
        })
            .then(response => {
                setformData(prevData => ({
                    ...prevData,
                    file_name: [...prevData.file_name, response.data.filename],
                    unique_file_name: [...prevData.unique_file_name, response.data.unique_filename]
                }))
                console.log('File uploaded successfully:', response.data)
            })
            // .catch(error => console.error('Error uploading file:', error.response.data.message));
            .catch((error) => { toast.error("Error uploading file" || error.response.data.message) })
    };

    // modified code for file deletion
    const handleFileDelete = (fileName) => {
        const gptName = formdata.gpt_name;
        let index = formdata.file_name.indexOf(fileName);
        let uniqueFileName = formdata.unique_file_name[index]
        axios.delete(`https://api.srvr2px.cyberads.io/cm_chatgpt/delete_file/${gptName}`, {
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
            },
            data: { unique_file_name: uniqueFileName }
        })
            .then(() => {
                setformData(prevData => ({
                    ...prevData,
                    file_name: prevData.file_name.filter(name => name !== fileName),
                    unique_file_name: prevData.unique_file_name.filter(name => name !== uniqueFileName)
                }));
                setProgress(prevProgress => {
                    const newProgress = { ...prevProgress };
                    delete newProgress[fileName];  // Deleting the progress key
                    return newProgress;
                });
                // Reset the file input value after deletion
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
            })
            .catch(error => {
                toast.error('Error deleting file' || error.response.data.message);
            });
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setformData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    // Define the file size limit
    const FILE_SIZE = 2 * 1024 * 1024; // 2 MB in bytes

    // Define the allowed file types
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

    const handleImageChange = (event) => {
        const image = event.target.files[0];
        if (!image) {
        //     setErrors({ ...errors, "logo": "No file selected" });
            return;
        }
        // Check file format
        if (!SUPPORTED_FORMATS.includes(image.type)) {
            setSelectedImage(null);  // Ensure no image is set when there's a format error
            setErrors({ ...errors, "logo": "Unsupported image format. Allowed formats: .jpg, .jpeg, .png" });
            return;
        }
        // Perform image size validation
        if (image.size > FILE_SIZE) {
            setSelectedImage(null);  // Ensure no image is set when there's a size error
            setErrors({ ...errors, "logo": "File size should not exceed 2 MB" });
            return;
        }

        // If both checks pass, set the image and clear previous errors
        setSelectedImage(URL.createObjectURL(image));
        setformData(prevData => ({
            ...prevData,
            logo: image
        }));
        setErrors({ ...errors, "logo": "" });  // Clearing the logo error if any
    };

    const handleImageClick = () => {
        document.getElementById("upload_image").click();
    };

    const save_user = async (e) => {
        e.preventDefault();
        try {
            //validate schema
            await validateSchema.validate(formdata, { abortEarly: false }
            );
            console.log(formdata);

            // If validation passes, proceed with saving the user
            const data = new FormData();
            data.append('email', formdata.email);
            data.append('user_type', formdata.user_type);
            data.append('account_type', formdata.account_type);
            data.append('gpt_name', formdata.gpt_name);
            data.append('description', formdata.description);
            data.append('prompt', formdata.prompt);
            data.append('conversational_starter', formdata.conversational_starter);
            if (formdata.logo) {
                data.append('logo', formdata.logo);
            }

            try {
                const response = await fetch("https://api.srvr2px.cyberads.io/cm_chatgpt/create_account", {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        // "Content-Type": "multipart/form-data",
                    },
                    body: data,
                });

                const result = await response.json();
                if (response.ok) {
                    // Successful response
                    toast.success("Account created successfully" || result.message);
                    navigatePreview(formdata.gpt_name, formdata.user_type);
                    // navigate(`/preview/${formdata.gpt_name}`);
                } else {
                    // API returned an error response
                    toast.error(result.message || "Fill the form carefully");
                }
            } catch (apiError) {
                // Handle fetch error (e.g., network error)
                toast.error("Something Went Wrong");
            }

        } catch (error) {
            const validationErrors = {};
            if (error.inner) {
                error.inner.forEach((err) => {
                    validationErrors[err.path] = err.message;
                });
            }
            setErrors(validationErrors);
        }
    };

    const navigatePreview = (gpt_name, user_type) => {
        navigate(`/preview/${gpt_name}`, { state: { user_type } });
    };


    return (
        <div>
            <Header header_name='New GPT' />
            <div className='p-5'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div className="mb-3" onClick={handleImageClick} style={{ cursor: 'pointer' }}>
                        {/* <img id="uploaded-image" className="rounded-circle" src="" alt="" width="100" height="100" /> */}
                        {selectedImage ? (
                            <div className="add-new-wrapper">
                                <img src={selectedImage} alt="Selected" className="rounded-circle" width="120" height="120" />
                                <img src={addnew} alt="Selected" className="rounded-circle default-change-image" />
                            </div>
                        ) : (
                            <div className="add-new-wrapper">
                                <img src={defaultprofile} alt="Selected" className="rounded-circle defaultprofile" width="120" height="120" />
                                <img src={addnew} alt="Selected" className="rounded-circle default-change-image" />
                            </div>
                        )
                        }
                    </div>
                    <div className="w-25">
                        {/* <label htmlFor="upload_image" className="form-label">Upload Image</label> */}
                        <input id="upload_image" className="form-control d-none" type="file" name="upload_image" accept=".jpg, .jpeg, .png" onChange={handleImageChange} />
                        {errors.logo && <div className="text-danger text-center font-14">{errors.logo}</div>}
                        {errors.imgSizeError && <div className="text-danger text-center font-14">{errors.imgSizeError}</div>}
                    </div>
                </div>
                <div className='row justify-content-center mt-4'>
                    <div className="col-lg-10">
                        <div className='d-flex flex-column'>
                            <div className='d-flex flex-row'>
                                <p className='font-500 mb-2 fw-bold'>Selection*</p>
                                <div className="form-check ms-3">
                                    <input className="form-check-input" type="radio" name="user_type" value="Internal" onChange={handleChange} id="flexRadioDefault1" />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Internal
                                    </label>
                                </div>
                                <div className="form-check ms-3">
                                    <input className="form-check-input" type="radio" name="user_type" value="External" onChange={handleChange} id="flexRadioDefault2" />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        External
                                    </label>
                                </div>
                            </div>
                            {errors.user_type && <div className="text-danger font-14 ms-3">{errors.user_type}</div>}
                            <select className="form-select w-100 mt-4" name="account_type" onChange={handleChange}>
                                <option disabled selected value="" className='d-none'>Business Unit</option>
                                <option value="All">All</option>
                                <option value="CMS">CMS</option>
                                <option value="CMR">CMR</option>
                                <option value="Media">Media</option>
                            </select>
                            {errors.account_type && <div className="text-danger font-14 mt-2">{errors.account_type}</div>}
                        </div>
                        <div className='mt-4'>
                            <form onSubmit={save_user}>
                                <div className="mb-3">
                                    <label htmlFor="nameInput" className="form-label fw-bold font-14">Name*</label>
                                    <input type="text" className="form-control" maxLength={50} id="nameInput" placeholder="Name" name="gpt_name" value={formdata.gpt_name} onChange={handleChange} onBlur={() => handleBlur("gpt_name")} />
                                    {errors.gpt_name && (<div className="text-danger font-14">{errors.gpt_name}</div>)}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="descriptionInput" className="form-label fw-bold font-14">Description*
                                    </label>
                                    <input className="form-control" id="descriptionInput"
                                        placeholder="Add a short description about what this GPT does" name="description" value={formdata.description} onChange={handleChange} onBlur={() => handleBlur("description")} />
                                    {errors.description && (<div className="text-danger font-14">{errors.description}</div>)}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="instructionsInput" className="form-label fw-bold font-14" >Instructions*
                                        <img className="info-icon mx-2" style={{ cursor: "pointer" }} data-tooltip-id='inst' src={info} alt="" />
                                    </label>
                                    <Tooltip id="inst" place="right" effect="solid">
                                        <p><strong>Tone:</strong> Friendly, professional, and informative.</p>
                                        <p><strong>Style:</strong> Clear, concise, and user-focused.</p>
                                    </Tooltip>
                                    <textarea className="form-control" id="instructionsInput" rows="3"
                                        placeholder="What does this GPT do? How does it behave? What should it avoid doing?" name="prompt" value={formdata.prompt} onChange={handleChange} onBlur={() => handleBlur("prompt")}>
                                    </textarea>
                                    {errors.prompt && (<div className="text-danger font-14">{errors.prompt}</div>)}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="conversationInput" className="form-label fw-bold font-14">Conversation Starters*
                                        <img className="info-icon mx-2" style={{ cursor: "pointer" }} data-tooltip-id='conversation' data-tooltip-content='Start here to say hello and begin the conversation.' src={info} alt="" />
                                    </label>
                                    <Tooltip id="conversation" />
                                    <input className="form-control" id="conversationInput"
                                        placeholder="Add a short description about what this GPT does" name="conversational_starter" value={formdata.conversational_starter} onChange={handleChange} onBlur={() => handleBlur("conversational_starter")} />
                                    {errors.conversational_starter && (<div className="text-danger font-14">{errors.conversational_starter}</div>)}
                                </div>
                                <p className='font-14 fw-bold mb-2'>Knowledge</p>
                                <p className='font-14 mb-3 text-color-a font-500'>If you upload files under Knowledge, conversations with your GPT may include file contents.
                                    Files can be downloaded when Code Interpreter is enabled</p>
                                <div className="mb-3">
                                    {
                                        formdata.gpt_name && uploading ?(
                                            <label htmlFor="upload_document" className="form-label btn btn-green text-white">Upload Files</label>
                                        ):
                                        (
                                            <label className="form-label btn btn-green text-white disabled">Upload Files</label>
                                        )
                                    }
                                    {/* <label htmlFor="upload_document" className="form-label btn btn-green text-white">Upload Files</label> */}
                                    <input id="upload_document" className="form-control d-none" type="file" multiple name="upload_document" accept=".pdf, .doc, .docx" onChange={handleFileChange} ref={fileInputRef} />
                                    {errors.file_limit && <div className="text-danger font-14">{errors.file_limit}</div>}
                                </div>
                                <div className='row'>
                                    {Object.entries(progress).map(([fileName, fileProgress]) => (
                                        <div className='col-12 col-lg-3 col-md-5 shadow-sm rounded rounded-3 bg-white p-4 ms-3 mt-3'>
                                            <div className='d-flex flex-row justify-content-between'>
                                                <div className='d-flex flex-row'>
                                                    <File className='me-1' size={20} color='#50B848' />
                                                    <div className='font-14 text-start' key={fileName}>
                                                        {fileName.length > 20 ? `${fileName.substring(0, 20)}...` : fileName} - {fileProgress}%
                                                    </div>
                                                </div>
                                                <Trash2 className='me-1' size={20} color='#DC3545' onClick={() => handleFileDelete(fileName)} // Attach delete handler
                                                    style={{ cursor: 'pointer' }} />

                                            </div>
                                            <div>
                                                <div className='mt-3 rounded' style={{ width: `${fileProgress}%`, backgroundColor: '#50B848', height: '10px' }}></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className='d-flex flex-row mt-5 justify-content-end'>
                                    <p></p>
                                    <button className='border-0 bg-transparent text-color-a font-500' type="button" onClick={() => { setShowModal(true); }}>Discard</button>
                                    <button type="submit" className="save-custom ms-3">Save</button>
                                </div>
                            </form>
                            {showModal && (
                                <div className="modal show" style={{ display: "block" }} role="dialog">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title fs-5">Discard changes?</h4>
                                                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <p className='text-color-a font-14 mb-0'>Are you sure you want to discard all changes?</p>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                                                <button type="button" className="btn btn-danger" onClick={() => { navigate("/") }}>Discard</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default Configue;
