import React, { useState } from 'react'
import Configue from './pages/Configue'
import Header from './component/Header'
import Sidebar from './component/Sidebar'
import Listing from './pages/Listing';
import Edit from './pages/Edit';
import { Route, Routes } from 'react-router-dom';
import Preview from './pages/Preview';
import { ToastContainer } from 'react-toastify';
import './fonts.css';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    console.log("hey")
  };

  return (
    <>
    <Routes>
      <Route path='/' element = { <Listing/> } />
      <Route path='/edit/:gpt_name' element = { <Edit/> }/>
      <Route path='/configue' element = { <Configue/> }/>
      <Route path='/preview/:gpt_name' element = { <Preview/> }/>
    </Routes>
    <ToastContainer/>

    </>
  )
}

export default App;