import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Edit2, Trash2 } from "react-feather";
import axios from "axios";

const Sidebar = ({ gpt_name, sessionid, onNewChat, onHistoryItemClick, onDelete }) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null)
  const [editedSessionName, setEditedSessionName] = useState("");

  // fuction for editing
  const handleEditClick = (item) => {
    setEditId(item._id);
    setEditedSessionName(item.session_name)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSaveClick();
    }
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.post("https://api.srvr2px.cyberads.io/cm_chatgpt/edit_session", { _id: editId, session_name: editedSessionName },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
        });

      if (response.status === 200) {
        setEditId(null);
        getData();
      }
    }
    catch (error) {
      console.log(error)
    }
  }


  // function to delete session
  const handle_Delete = async () => {
    try {
      const response = await axios.post("https://api.srvr2px.cyberads.io/cm_chatgpt/delete_session", { _id: deleteId, gpt_name: gpt_name },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
        });

      if (response.status === 200) {
        getData();
        onDelete(1);
      }
    }
    catch (error) {
      console.log(error)
    }
    finally {
      setShowModal(false);
    }
  }




  const getData = async () => {
    try {
      const response = await fetch(
        `https://api.srvr2px.cyberads.io/cm_chatgpt/history_list/${gpt_name}`
      );
      if (response.ok) {
        const result = await response.json();
        // console.log(result);
        // console.log(result.response)
        setData(result.response);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (gpt_name) {
      getData();
    }
  }, [sessionid]);



  return (
    <div className="sidebar-style sidebar-width-preview">
      {/* <div className={`sidebar ${isOpen ? 'open' : ''}`}> */}
      <div className="d-flex justify-content-center">
        <button
          className="new-chat border-0 rounded-3 w-75 mt-3 text-white"
          onClick={onNewChat}
        >
          New Chat +
        </button>
      </div>
      <div className={`sidebar overflow-y-scroll`} style={{ marginTop: "20px" }}>
        <div className="sidebar-content">
          <ul className="list-unstyled">
            {data.map((item, index) => (
              <div className="d-flex flex-row justify-content-between history-content align-items-center">
                <div className="p-0 m-0">
                  {editId === item._id ? (
                    <input
                      type="text"
                      value={editedSessionName}
                      onChange={(e) => { setEditedSessionName(e.target.value) }}
                      onKeyDown={handleKeyPress}
                      // onBlur={() => handleSaveClick(item.session_id)}
                      className="form-control"
                    />
                  ) : (
                    <li
                      className="py-3 ps-2 font-14"
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => onHistoryItemClick(item)}
                    >
                      {/* {item.session_name} */}
                      {item.session_name.length > 20 ? `${item.session_name.slice(0, 20)}...` : item.session_name}
                    </li>
                  )}
                </div>
                <div className="p-0 m-0">
                  <button className="border-0 bg-transparent">
                    <Edit2 className="" size={16} color="#f6f6f6" onClick={() => { handleEditClick(item) }} />
                  </button>
                  <button className="border-0 bg-transparent">
                    <Trash2 className="" size={16} color="#f6f6f6" onClick={() => {
                      setShowModal(true);
                      setDeleteId(item._id);
                    }} />
                  </button>
                </div>
              </div>
            ))}
          </ul>
        </div>
      </div>
      {showModal && (
        <div className="modal show" style={{ display: "block" }} role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title fs-5">Delete chat?</h4>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p className='text-color-a font-14 mb-0'>Are you sure you want to delete this conversation?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={handle_Delete}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
