import React, { useState, useEffect, useRef } from "react";
import "../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Modal } from "bootstrap";
import defaultprofile from "../images/defaultprofile.png";
import Googledocs from "../images/Googledocs.png";
import PDF from "../images/PDF.png";
import * as yup from "yup";
import axios from "axios";
import { Trash2, File } from "react-feather";
import { useParams, useNavigate } from "react-router-dom";
import Header from '../component/Header';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import addnew from "../images/addnew.png";


function Edit() {
  const [formdata, setformData] = useState({
    user_type: "",
    account_type: "",
    gpt_name: "",
    description: "",
    prompt: "",
    conversational_starter: "",
    original_file_name: [],
    unique_file_name: [],
    logo: null,
    logo_path: "",
    file_path: "",
    files_name: [],
    updated_file: ""
  });

  //api data
  // const [apiData, setApiData] = useState({
  //   user_type: "",
  //   account_type: "",
  //   gpt_name: "",
  //   description: "",
  //   prompt: "",
  //   conversational_starter: "",
  //   logo_path: "",
  //   file_path: "",
  //   files_name: [],
  // });
  const { gpt_name } = useParams();

  const fileInputRef = useRef(null);
  const [progress, setProgress] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [errors, setErrors] = useState({});
  // const [apiresponse, setApiresponse] = useState("");
  // const [apierror, setApierror] = useState("");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    await fetch(`https://api.srvr2px.cyberads.io/cm_chatgpt/data/${gpt_name}`)
      .then((response) => response.json())
      .then((result) => {
        setformData((prevData) => ({
          ...prevData,
          ...result,
        }));
      })
      .catch(() => {
        toast.error("something went wrong")
      });
  };

  // function to show pdf and docs icon
  const getFileIcon = (fileName) => {
    if (fileName.endsWith('.pdf')) {
      return PDF;
    } else if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
      return Googledocs;
    }
  };

  //function to delete preuploaded file
  const delete_uploaded_file = (fileName) => {
    let uniqueFileName = fileName;
    axios.delete(`https://api.srvr2px.cyberads.io/cm_chatgpt/delete_file/${gpt_name}`, {
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
      },
      data: { unique_file_name: uniqueFileName },
    })
      .then(() => {
        setformData((prevData) => ({
          ...prevData,
          files_name: prevData.files_name.filter((name) => name !== fileName),
          updated_file: "file_deleted"
        }));
      })
      .catch((error) => {
        // console.error("Error deleting file:", error.response.data.message);
        // setApierror("Error deleting file");
        toast.error("Error deleting file")
      });
  };

  const validateSchema = yup.object({
    // logo: yup.mixed().required("Logo is required"),
    description: yup.string().required("Description is required"),
    prompt: yup.string().required("Instructions is required"),
    conversational_starter: yup
      .string()
      .required("conversation starter is required"),

  });

  const handleBlur = async (field) => {
    try {
      // Directly pass formdata to validateAt
      await validateSchema.validateAt(field, formdata);
      setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
    }
  };

  // for uploading file
  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 6) {
      // If more than six files are selected, display an error message
      setErrors({
        ...errors,
        file_limit: "You can upload a maximum of six files",
      });
      return;
    }
    // Process each file immediately after it is selected
    Array.from(files).forEach((file) => {
      setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 0 })); // Initialize progress
      uploadFile(file); // Name is accessed directly within uploadFile
    });
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("gpt_name", gpt_name); // Directly use the name from the state
    formData.append("files", file);

    axios
      .post(
        "https://api.srvr2px.cyberads.io/cm_chatgpt/upload_files",
        formData,
        {
          headers: {
            Accept: "application/json",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: percentCompleted,
            }));
          },
        }
      )
      .then((response) => {
        setformData((prevData) => ({
          ...prevData,
          original_file_name: [
            ...prevData.original_file_name,
            response.data.filename,
          ],
          unique_file_name: [
            ...prevData.unique_file_name,
            response.data.unique_filename,
          ],
          updated_file: "file_added"
        }));
        console.log("File uploaded successfully:", response.data);
      })
      .catch((error) =>
        {toast.error("Error uploading file"|| error.response.data.message)}
      );
  };

  // Function for file deletion
  const handleFileDelete = (fileName) => {
    let index = formdata.original_file_name.indexOf(fileName);
    let uniqueFileName = formdata.unique_file_name[index];
    axios
      .delete(
        `https://api.srvr2px.cyberads.io/cm_chatgpt/delete_file/${gpt_name}`,
        {
          data: { unique_file_name: uniqueFileName },
        }
      )
      .then(() => {
        setformData((prevData) => ({
          ...prevData,
          original_file_name: prevData.original_file_name.filter(
            (name) => name !== fileName
          ),
          unique_file_name: prevData.unique_file_name.filter(
            (name) => name !== uniqueFileName
          ),
          updated_file: "file_deleted"
        }));
        setProgress((prevProgress) => {
          const newProgress = { ...prevProgress };
          delete newProgress[fileName]; // Deleting the progress key
          return newProgress;
        });
        // Reset the file input value after deletion
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      })
      .catch((error) => {
        toast.error('Error deleting file' || error.response.data.message);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Define the file size limit
  const FILE_SIZE = 2 * 1024 * 1024; // 2 MB in bytes

  // Define the allowed file types
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    if (!image) {
      // setErrors({ ...errors, logo: "No file selected" });
      return;
    }
    // Check file format
    if (!SUPPORTED_FORMATS.includes(image.type)) {
      setSelectedImage(null); // Ensure no image is set when there's a format error
      setErrors({
        ...errors,
        logo: "Unsupported image format. Allowed formats: .jpg, .jpeg, .png",
      });
      return;
    }
    // Perform image size validation
    if (image.size > FILE_SIZE) {
      setSelectedImage(null); // Ensure no image is set when there's a size error
      setErrors({ ...errors, logo: "File size should not exceed 2 MB" });
      return;
    }

    // If both checks pass, set the image and clear previous errors
    setSelectedImage(URL.createObjectURL(image));
    setformData((prevData) => ({
      ...prevData,
      logo: image,
    }));
    setErrors({ ...errors, logo: "" }); // Clearing the logo error if any
  };

  const handleImageClick = () => {
    document.getElementById("upload_image").click();
  };

  const save_user = async (e) => {
    e.preventDefault();
    try {
      // Validate schema
      await validateSchema.validate(formdata, { abortEarly: false });
      console.log(formdata);

      // If validation passes, proceed with saving the user
      const data = new FormData();
      // let isDataChanged = false;
      if (formdata.logo){
        data.append("logo", formdata.logo);
      }
      data.append("user_type", formdata.user_type);
      data.append("account_type", formdata.account_type);
      data.append("description", formdata.description);
      data.append("prompt", formdata.prompt);
      data.append("conversational_starter", formdata.conversational_starter);

      if (formdata.updated_file) {
        data.append("update_file", formdata.updated_file);
      }

      // if (!isDataChanged) {
      //   setApierror("No changes to save.");
      //   return;
      // }

      fetch(`https://api.srvr2px.cyberads.io/cm_chatgpt/edit_account/${gpt_name}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          // "Content-Type": "multipart/form-data", // Do not set this header for FormData
        },
        body: data,
      })
        .then((response) => {
          if (response.ok){
            toast.success("Changes Saved");
            navigate('/');
          }
        })
        .catch(() => {toast.error("Something Went Wrong")});
    } catch (error) {
      const validationErrors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
      }
      setErrors(validationErrors);
    }
  };

  return (
    <div>
      <Header header_name='Edit GPT' />
      <div className="p-5">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div
            className="mb-3"
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          >
            {/* <img id="uploaded-image" className="rounded-circle" src="" alt="" width="100" height="100" /> */}
            {selectedImage ? (
              <div className="add-new-wrapper">
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="rounded-circle"
                  width="120"
                  height="120"
                />
                <img src={addnew} alt="Selected" className="rounded-circle default-change-image" />
              </div>
            ) : (
              <div className="add-new-wrapper">
                <img
                  src={formdata.logo_path || defaultprofile}
                  alt="Selected"
                  className="rounded-circle"
                  width="120"
                  height="120"
                />
                <img src={addnew} alt="Selected" className="rounded-circle default-change-image" />
              </div>
            )}
            {/* <p className="font-500 mt-2 mb-0 text-center">Upload New</p> */}
          </div>
          <div className="w-25">
            {/* <label htmlFor="upload_image" className="form-label">Upload Image</label> */}
            <input
              id="upload_image"
              className="form-control d-none"
              type="file"
              name="upload_image"
              accept=".jpg, .jpeg, .png"
              onChange={handleImageChange}
            />
            {errors.logo && (
              <div className="text-danger text-center font-14">{errors.logo}</div>
            )}
            {errors.imgSizeError && (
              <div className="text-danger text-center font-14">{errors.imgSizeError}</div>
            )}
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-lg-10">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row">
                <p className="font-500 mb-2">Selection:</p>
                <div className="form-check ms-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="user_type"
                    value="Internal"
                    onChange={handleChange}
                    id="flexRadioDefault1"
                    checked={formdata.user_type === "Internal"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Internal
                  </label>
                </div>
                <div className="form-check ms-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="user_type"
                    value="External"
                    onChange={handleChange}
                    id="flexRadioDefault2"
                    checked={formdata.user_type === "External"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    External
                  </label>
                </div>
              </div>
              <select
                className="form-select w-100 mt-4"
                name="account_type"
                onChange={handleChange}
                value={formdata.account_type}
              >
                <option disabled selected value="" className="d-none">
                  Business Unit
                </option>
                <option value="All">All</option>
                <option value="CMS">CMS</option>
                <option value="CMR">CMR</option>
                <option value="Media">Media</option>
              </select>
            </div>
            <div className="mt-4">
              <form onSubmit={save_user}>
                <div className="mb-3">
                  <label
                    htmlFor="nameInput"
                    className="form-label fw-bold font-14"
                  >
                    Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    maxLength={50}
                    id="nameInput"
                    placeholder="Name"
                    name="gpt_name"
                    value={formdata.gpt_name}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="descriptionInput"
                    className="form-label fw-bold font-14"
                  >
                    Description*
                  </label>
                  <input
                    className="form-control"
                    id="descriptionInput"
                    placeholder="Add a short description about what this GPT does"
                    name="description"
                    value={formdata.description}
                    onChange={handleChange}
                    onBlur={() => handleBlur("description")}
                  />
                  {errors.description && (
                    <div className="text-danger font-14">{errors.description}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="instructionsInput"
                    className="form-label fw-bold font-14"
                  >
                    Instructions*
                  </label>
                  <textarea
                    className="form-control"
                    id="instructionsInput"
                    rows="3"
                    placeholder="What does this GPT do? How does it behave? What should it avoid doing?"
                    name="prompt"
                    value={formdata.prompt}
                    onChange={handleChange}
                    onBlur={() => handleBlur("prompt")}
                  ></textarea>
                  {errors.prompt && (
                    <div className="text-danger font-14">{errors.prompt}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="conversationInput"
                    className="form-label fw-bold font-14"
                  >
                    Conversation Starters*
                  </label>
                  <input
                    className="form-control"
                    id="conversationInput"
                    placeholder="Add a short description about what this GPT does"
                    name="conversational_starter"
                    value={formdata.conversational_starter}
                    onChange={handleChange}
                    onBlur={() => handleBlur("conversational_starter")}
                  />
                  {errors.conversational_starter && (
                    <div className="text-danger font-14">
                      {errors.conversational_starter}
                    </div>
                  )}
                </div>
                <p className="font-14 fw-bold mb-2">Knowledge</p>
                <p className="font-14 mb-3 text-color-a font-500">
                  If you upload files under Knowledge, conversations with your GPT
                  may include file contents. Files can be downloaded when Code
                  Interpreter is enabled
                </p>
                <div className="mb-3">
                  <label
                    htmlFor="upload_document"
                    className="form-label btn btn-green text-white"
                  >
                    Upload Files
                  </label>
                  <input
                    id="upload_document"
                    className="form-control d-none"
                    type="file"
                    multiple
                    name="upload_document"
                    accept=".pdf, .doc, .docx"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                  {errors.file_limit && (
                    <div className="text-danger font-14">{errors.file_limit}</div>
                  )}
                </div>
                <div className="row">
                  {Object.entries(progress).map(([fileName, fileProgress]) => (
                    <div className="col-12 col-lg-3 col-md-5 shadow-sm rounded rounded-3 bg-white p-4 ms-0 ms-lg-3 ms-md-3 mt-3">
                      <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-row">
                          <File className="me-1" size={20} color="#50B848" />
                          <div className="font-14 text-start" key={fileName}>
                            {fileName.length > 20
                              ? `${fileName.substring(0, 20)}...`
                              : fileName}{" "}
                            - {fileProgress}%
                          </div>
                        </div>
                        <Trash2
                          className="me-1"
                          size={20}
                          color="#DC3545"
                          onClick={() => handleFileDelete(fileName)} // Attach delete handler
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div>
                        <div
                          className="mt-3 rounded"
                          style={{
                            width: `${fileProgress}%`,
                            backgroundColor: "#50B848",
                            height: "10px",
                          }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* showing files */}
                {formdata.files_name.length > 0 &&
                  <div className="mt-5">
                    <h5>Previously Uploaded Files</h5>
                    <ul className="list-unstyled">
                      <div className="row">
                        {formdata.files_name.map((fileName, index) => (
                          <div className="col-12 col-lg-5 col-md-12 ms-0 ms-lg-3 d-flex flex-column bg-white shadow-sm p-3 mt-3">
                            <div className="d-flex flex-row justify-content-between">
                              <div className="d-flex flex-row">
                                <img className="uploaded-files-format" src={getFileIcon(fileName)} alt="" />
                                <li className="mt-1 mx-4" key={index}>
                                  <a
                                    className="text-decoration-none text-black"
                                    href={formdata.file_path + fileName}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {fileName.length > 20 ? `${fileName.substring(0, 20)}...` : fileName}
                                  </a>
                                </li>
                              </div>
                              <div>
                                <Trash2
                                  className="me-1 mt-2"
                                  size={20}
                                  color="#DC3545"
                                  onClick={() => delete_uploaded_file(fileName)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </ul>
                  </div>}


                <div className="d-flex flex-row mt-5 justify-content-end">
                  <p></p>
                  <button
                    className="border-0 bg-transparent text-color-a font-500"
                    type="button"
                    onClick={()=>{setShowModal(true);}}
                  >
                    Discard
                  </button>
                  <button type="submit" className="save-custom ms-3">
                    Save
                  </button>
                </div>
              </form>
              {showModal && (
                <div className="modal show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title fs-5">Discard changes?</h4>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p className='text-color-a font-14 mb-0'>Are you sure you want to discard all changes?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                                <button type="button" className="btn btn-danger" onClick={()=>{navigate("/")}}>Discard</button>
                            </div>
                        </div>
                    </div>
                </div>
                    )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
